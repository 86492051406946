<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
import has from "lodash/has";
import { mapGetters } from "vuex";
export default {
  name: "PublicView",
  created() {
    if (
      this.companies &&
      this.lastroute &&
      this.lastroute.params &&
      this.lastroute.params.company
    ) {
      if (has(this.companies, [this.lastroute.params.company])) {
        this.$router.push(this.lastroute.path);
      }
    }
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
      lastroute: "lastroute",
    }),
  },
};
</script>
